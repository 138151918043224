import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Dropdown, Alert } from 'react-bootstrap';
import { BsGlobe } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../img/login_logo.png';
import loginRightBg from '../img/login_right_bg.jpg';
import '../App.css';
import RegisterModal from './RegisterModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import { useTranslation } from 'react-i18next';
import i18n from '../languages';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [error, setError] = useState('');

  const handleRegisterClick = () => {
    setShowRegisterModal(true);
  };

  const handleCloseModal = () => {
    setShowRegisterModal(false);
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      changeLanguage(savedLanguage);
    } else {
      changeLanguage('ee'); // Default language if no preference is saved
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const response = await fetch('https://palk.itabiks.ee/actions/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        // Store authentication token in session storage
        sessionStorage.setItem('authToken', data.token); // or localStorage.setItem('authToken', data.token);
        // Redirect to dashboard
        navigate('/dashboard');
      } else {
        setError(data.error || 'Login failed');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission
      document.getElementById('loginButton').click(); // Programmatically click the login button
    }
  };

  return (
    <Container fluid>
      <Row className="vh-100">
        <Col md={6} className="d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
          <Button
            variant="secondary"
            type="button"
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#071A2B',
              color: 'white',
              borderColor: '#FFFFFF',
              zIndex: 10,
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#00A3E0')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#071A2B')}
            onClick={handleRegisterClick}
          >
            {t('registration_btn')}
          </Button>
          <Form style={{ width: '80%', textAlign: 'center' }} onKeyDown={handleKeyDown} onSubmit={handleLogin}>
            <img src={logo} alt="Login" className="mb-4 loginLogo" style={{ margin: '0 auto' }} />
            <h2 className="mb-4">{t('salary_label')}</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Control
                type="email"
                placeholder={t("placeholder_login_email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <Form.Control
                type="password"
                placeholder={t("placeholder_login_password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <div className="d-grid gap-2">
              <Button
                id="loginButton"
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: '#00A3E0',
                  color: 'white',
                  borderColor: '#FFFFFF',
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#071A2B')}
                onMouseOut={(e) => (e.target.style.backgroundColor = '#00A3E0')}
              >
                {t('login_btn')}
              </Button>
              <Button
                variant="primary"
                type="button"
                style={{
                  backgroundColor: '#00A3E0',
                  color: 'white',
                  borderColor: '#FFFFFF',
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#071A2B')}
                onMouseOut={(e) => (e.target.style.backgroundColor = '#00A3E0')}
                onClick={handleForgotPasswordClick}
              >
                {t('forgot_password_btn')}
              </Button>
            </div>
          </Form>
        </Col>
        <Col md={6} className="d-none d-md-block p-0">
          <div
            style={{
              backgroundImage: `url(${loginRightBg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
            }}
          ></div>
        </Col>
      </Row>
      <RegisterModal show={showRegisterModal} handleClose={handleCloseModal} />
      <ForgotPasswordModal show={showForgotPasswordModal} handleClose={handleCloseForgotPasswordModal} />
      <Dropdown style={{ position: 'absolute', top: '20px', left: '20px', zIndex: 10 }}>
        <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ color: '#000000', textDecoration: 'none' }}>
          <BsGlobe style={{ marginRight: '5px' }} /> {/* Use world icon */}
          {i18n.language.toUpperCase()}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => changeLanguage('ee')}>EE</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
          <Dropdown.Item onClick={() => changeLanguage('ru')}>RU</Dropdown.Item>
          {/* Add more language options as needed */}
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default LoginPage;