import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../languages';

const RegisterModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    company: '',
    password: '',
    confirmPassword: ''
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      changeLanguage(savedLanguage);
    } else {
      changeLanguage('ee');
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate passwords
    if (!validatePassword(formData.password)) {
      setMessage(t('register_password_invalid'));
      setMessageType('danger');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setMessage(t('register_passwords_not_matching'));
      setMessageType('danger');
      return;
    }

    try {
      const response = await fetch('https://palk.itabiks.ee/actions/registration.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const text = await response.text(); // Read response as text
      let data;
      try {
        data = JSON.parse(text); // Try to parse as JSON
      } catch (error) {
        console.error('Error parsing JSON:', error);
        console.log('Response text:', text);
        throw new Error('Invalid JSON in response');
      }

      if (response.ok) {
        setMessage(t('register_success'));
        setMessageType('success');
        setTimeout(() => {
          handleClose();
        }, 3000);
      } else {
        setMessage(data.error || t('register_failure'));
        setMessageType('danger');
      }
    } catch (error) {
      setMessage(t('register_failure'));
      setMessageType('danger');
      console.error('There was an error!', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('register_label')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && (
          <Alert variant={messageType}>
            {message}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Control
              type="email"
              name="email"
              placeholder={t('register_placeholder_email')}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicFirstName" className="mb-3">
            <Form.Control
              type="text"
              name="firstName"
              placeholder={t('register_placeholder_firstname')}
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicLastName" className="mb-3">
            <Form.Control
              type="text"
              name="lastName"
              placeholder={t('register_placeholder_lastname')}
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPhone" className="mb-3">
            <Form.Control
              type="text"
              name="phone"
              placeholder={t('register_placeholder_phone')}
              value={formData.phone}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formBasicCompany" className="mb-3">
            <Form.Control
              type="text"
              name="company"
              placeholder={t('register_placeholder_company')}
              value={formData.company}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword" className="mb-3">
            <Form.Control
              type="password"
              name="password"
              placeholder={t('register_placeholder_password')}
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicConfirmPassword" className="mb-3">
            <Form.Control
              type="password"
              name="confirmPassword"
              placeholder={t('register_placeholder_2password')}
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formBasicCheckbox" className="mb-3">
            <Form.Check
              type="checkbox"
              label={t('register_agree_terms')}
              required
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            style={{
              backgroundColor: '#00A3E0',
              color: 'white',
              borderColor: '#FFFFFF',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#071A2B')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#00A3E0')}
          >
            {t('register_btn_register')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterModal;