import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Dropdown, Nav, Card, Button, Table } from 'react-bootstrap';
import { BsHouse, BsGear, BsPerson, BsBoxArrowRight, BsGlobe, BsList } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import '../Dashboard.css';
import logo_white from '../img/logo_white.png';


const Dashboard = ({ language, changeLanguage }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('authToken'); // Clear session/local storage
        window.location.href = '/'; // Redirect to login page
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const UsersTable = () => {
        const [users, setUsers] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);

        useEffect(() => {
            fetch('http://localhost/users.php')
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch users');
                    }
                    return response.json();
                })
                .then(data => {
                    setUsers(data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error.message);
                    setLoading(false);
                });
        }, []);

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            {/* Add more columns as needed */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    return (
        <Container fluid>
            <Row className="d-md-none"> {/* Show only on mobile devices */}
                <Col>
                    <div className="mobile-header">
                        <div className="header-logo">
                            <img src={logo_white} alt="Logo" />
                        </div>
                        <div className="menu-icon" onClick={toggleMenu}>
                            <BsList />
                        </div>
                    </div>
                    {showMenu && (
                        <div className="mobile-menu">
                            <Nav className="flex-column">
                                <Nav.Link href="#home"><BsHouse /> {t('btn_menu_home')}</Nav.Link>
                                <Nav.Link href="#settings"><BsGear /> {t('btn_menu_settings')}</Nav.Link>
                                <Nav.Link href="#profile"><BsPerson /> {t('btn_menu_profile')}</Nav.Link>
                                <Dropdown className="language-switcher">
                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                        <BsGlobe /> {language.toUpperCase()}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => changeLanguage('ee')}>EE</Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage('ru')}>RU</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        </div>
                    )}
                </Col>
            </Row>
            <Row className="d-none d-md-flex"> {/* Show on larger screens */}
                <Col md={2} className="sidebar">
                    <div className="sidebar-logo">
                        <img src={logo_white} alt="Logo" />
                    </div>
                    <Nav className="flex-column sidebar-nav">
                        <Nav.Link href="#home" className="sidebar-link"><BsHouse className="sidebar-icon" /> {t('btn_menu_home')}</Nav.Link>
                        <Nav.Link href="#settings" className="sidebar-link"><BsGear className="sidebar-icon" /> {t('btn_menu_settings')}</Nav.Link>
                        <Nav.Link href="#profile" className="sidebar-link"><BsPerson className="sidebar-icon" /> {t('btn_menu_profile')}</Nav.Link>
                    </Nav>
                    <Dropdown className="language-switcher">
                        <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ color: '#FFFFFF', textDecoration: 'none' }}>
                            <BsGlobe style={{ marginRight: '5px' }} /> {language.toUpperCase()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => changeLanguage('ee')}>EE</Dropdown.Item>
                            <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
                            <Dropdown.Item onClick={() => changeLanguage('ru')}>RU</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col md={10} className="main-content">
                    <div className="top-bar">
                        <div className="welcome-text">{t('dashboard_welcome_text')}</div>
                        <Dropdown className="profile-menu">
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="profile-button">
                                <BsPerson className="profile-icon" /> {t('btn_menu_profile')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#settings"><BsGear /> {t('btn_menu_settings')}</Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout} style={{ color: 'red' }}><BsBoxArrowRight /> {t('btn_menu_logout')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="content" style={{ marginTop: '20px', marginLeft: '20px' }}>
                        <Row>
                            {/* Add your content rows here */}
                            <Col xs={12} md={4}>
                                <Card style={{ width: '18rem', marginBottom: '20px' }}>
                                    <Card.Img variant="top" src="https://picsum.photos/100/100" />
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <Button variant="primary">Go somewhere</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img variant="top" src="https://picsum.photos/100/100" />
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <Button variant="primary">Go somewhere</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Img variant="top" src="https://picsum.photos/100/100" />
                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Card.Text>
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <Button variant="primary">Go somewhere</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <UsersTable />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;