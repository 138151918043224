import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './LoginPage/LoginPage';
import RegisterModal from './LoginPage/RegisterModal';
import ForgotPasswordModal from './LoginPage/ForgotPasswordModal';
import Dashboard from './Dashboard/Dashboard'; // Import Dashboard
import PrivateRoute from './Dashboard/PrivateRoute';

const App = () => {
  const [language, setLanguage] = useState('ee');

  const changeLanguage = (lng) => {
    setLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={<LoginPage changeLanguage={changeLanguage} language={language} />}
          />
          <Route
            path="/register"
            element={<RegisterModal language={language} changeLanguage={changeLanguage} />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPasswordModal language={language} changeLanguage={changeLanguage} />}
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard language={language} changeLanguage={changeLanguage} />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;