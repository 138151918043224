import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ForgotPasswordModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://palk.itabiks.ee/actions/forgot_password.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.success);
        handleClose();
      } else {
        alert(data.error);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('forgot_password_label')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Label>{t('forgot_password_email_label')}</Form.Label>
            <Form.Control 
              type="email" 
              placeholder={t('forgot_password_placeholder')} 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            style={{
              backgroundColor: '#00A3E0',
              color: 'white',
              borderColor: '#FFFFFF',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#071A2B')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#00A3E0')}
          >
            {t('forgot_password_btn2')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;